import React, { PureComponent } from 'react'
import styles from './button.module.scss'
import classNames from 'classnames'
import { IDefaultProps, IProps } from './types'

export default class Button extends PureComponent<IProps> {
  static defaultProps: IDefaultProps = {
    className: '',
    size: 'medium',
    textSize: 'medium',
    type: 'submit',
    style: 'fill',
    styleType: 'primary',
    title: '',
    disabled: false,
    onClick: () => {},
    icon: undefined,
    iconPosition: 'left',
    textPosition: 'center',
    href: '',
    target: '',
    id: '',
    subTitle: '',
  }
  render() {
    const {
      className,
      size,
      type,
      title,
      disabled,
      onClick,
      style,
      styleType,
      icon,
      iconPosition,
      href,
      target,
      textSize,
      id,
      subTitle,
      textPosition,
    } = this.props

    let iconElement
    if (icon) {
      iconElement = React.cloneElement(icon, {
        className: classNames(styles[`button__icon`], {
          [styles[`button__icon--${style}`]]: style,
          [styles[`button__icon--${style}--${textSize}`]]: size,
          [styles[`button__icon--${style}--${textSize}--${styleType}`]]: size,
          [styles[
            `button__icon--${style}--${textSize}--${styleType}--sub-title`
          ]]: subTitle,
          [styles[
            `button__icon--${style}--${textSize}--${styleType}--disabled`
          ]]: disabled,
        }),
      })
    }

    if (href) {
      return (
        <a
          href={href}
          target={target}
          type={type}
          onClick={onClick}
          id={id}
          className={classNames(styles['button'], {
            [styles[`button--${style}`]]: style,
            [styles[`button--${size}`]]: size,
            [styles[`button--${size}--sub-title`]]: subTitle,
            [styles[`button--${style}--${styleType}`]]: styleType,
            [styles[`button--${style}--${styleType}--disabled`]]: disabled,
            [className as string]: className,
          })}
        >
          {icon && iconPosition === 'left' && iconElement}
          {title && (
            <span
              className={classNames(styles[`button__title`], {
                [styles[`button__title--${style}`]]: style,
                [styles[`button__title--${style}--${textSize}`]]: size,
                [styles[
                  `button__title--${style}--${textSize}--${styleType}`
                ]]: styleType,
                [styles[
                  `button__title--${style}--${textSize}--${styleType}--text-left`
                ]]: textPosition === 'left',
                [styles[
                  `button__title--${style}--${textSize}--${styleType}--sub-title`
                ]]: subTitle,
                [styles[
                  `button__title--${style}--${textSize}--${styleType}--disabled`
                ]]: disabled,
              })}
            >
              {title}
            </span>
          )}
          {subTitle && (
            <span
              className={classNames(styles[`button__sub-title`], {
                [styles[`button__sub-title--${style}`]]: style,
                [styles[`button__sub-title--${style}--${textSize}`]]: size,
                [styles[
                  `button__sub-title--${style}--${textSize}--${styleType}`
                ]]: styleType,
                [styles[
                  `button__sub-title--${style}--${textSize}--${styleType}--disabled`
                ]]: disabled,
              })}
            >
              {subTitle}
            </span>
          )}
          {icon && iconPosition === 'right' && iconElement}
        </a>
      )
    }

    return (
      <button
        type={type}
        onClick={onClick}
        id={id}
        disabled={disabled}
        className={classNames(styles['button'], {
          [styles[`button--${style}`]]: style,
          [styles[`button--${size}`]]: size,
          [styles[`button--${size}--sub-title`]]: subTitle,
          [styles[`button--${style}--${styleType}`]]: styleType,
          [styles[`button--${style}--${styleType}--disabled`]]: disabled,
          [className as string]: className,
        })}
      >
        {icon && iconPosition === 'left' && iconElement}
        {title && (
          <span
            className={classNames(styles[`button__title`], {
              [styles[`button__title--${style}`]]: style,
              [styles[`button__title--${style}--${textSize}`]]: size,
              [styles[
                `button__title--${style}--${textSize}--${styleType}`
              ]]: styleType,
              [styles[
                `button__title--${style}--${textSize}--${styleType}--sub-title`
              ]]: subTitle,
              [styles[
                `button__title--${style}--${textSize}--${styleType}--text-left`
              ]]: textPosition === 'left',
              [styles[
                `button__title--${style}--${textSize}--${styleType}--disabled`
              ]]: disabled,
            })}
          >
            {title}
          </span>
        )}
        {subTitle && (
          <span
            className={classNames(styles[`button__sub-title`], {
              [styles[`button__sub-title--${style}`]]: style,
              [styles[`button__sub-title--${style}--${textSize}`]]: size,
              [styles[
                `button__sub-title--${style}--${textSize}--${styleType}`
              ]]: styleType,
              [styles[
                `button__sub-title--${style}--${textSize}--${styleType}--disabled`
              ]]: disabled,
            })}
          >
            {subTitle}
          </span>
        )}
        {icon && iconPosition === 'right' && iconElement}
      </button>
    )
  }
}
