/* eslint-disable max-lines-per-function */
import React, { FunctionComponent } from 'react'
import { Button } from '../../components/button'
import { Icon } from '../../components/icon-secured'
import { Icons } from '../../components/icon-secured/icon'
import styles from './landing.module.scss'

const Landing: FunctionComponent = () => {
  return (
    <div className={styles.landing}>
      <div className={styles.container}>
        <div className={styles.landing__header}>
          <div className={styles.landing__header__inner}>
            <div className={styles.landing__header__inner__logo}>
              <img
                className={styles.landing__header__inner__logo__image}
                src="/images/bumpLogo.svg"
                alt="Bump Logo"
                title="Bump Logo"
              />
            </div>
          </div>
        </div>
        <div className={styles.landing__main}>
          <div className={styles.landing__main__message}>
            <h1 className={styles.landing__main__message__title}>
              Bye BUMP → Hello Secured 👋
            </h1>
            <p className={styles.landing__main__message__paragraph}>
              For the past 5 years, we’ve been proud to work on the BUMP
              marketplace and help millions of you buy and sell sneakers &
              streetwear. We've been able to meet, speak with and learn from
              thousands of people which has helped shape BUMP and what we're
              working on next, Secured.
            </p>

            <p className={styles.landing__main__message__paragraph}>
              We realised that we wanted to help people cop sneakers &
              streetwear for retail instead of building another place to pay
              resale prices.
            </p>

            <p className={styles.landing__main__message__paragraph}>
              Today, you are no longer able to buy and sell on BUMP but you can
              still access your order history. On Friday 9th April we will be
              removing access to our website and apps. If you have recently
              purchased/sold an item with us, we will do our best to fulfil the
              order. If we are unable to fulfil your order, you will receive a
              full refund or your item will be returned to you.
            </p>

            <p className={styles.landing__main__message__paragraph}>
              Our new product, Secured (secured.community), helps you purchase
              sneakers & streetwear before they sell out. With a Secured
              membership you get access to our community and tools like sneaker
              bots, early links and raffles all in one place. To say thanks, you
              can use code "BUMP" to get 50% off your first month - we know
              you're going to love it.
            </p>
            <p className={styles.landing__main__message__paragraph}>
              See you in Secured 🚀
            </p>
            <p className={styles.landing__main__message__paragraph}>
              Jack, Sam & the whole BUMP Team
            </p>
          </div>

          <Button
            className={styles.landing__main__button}
            title="Visit Secured"
            iconPosition="right"
            size="large"
            icon={<Icon type={Icons['external-link']} />}
            href="https://secured.community/"
          />

          <div className={styles['landing__main__secured-banner']}>
            <div className={styles['landing__main__secured-banner__content']}>
              <div
                className={
                  styles['landing__main__secured-banner__content__title']
                }
              >
                Join Secured
              </div>
              <div
                className={
                  styles['landing__main__secured-banner__content__text']
                }
              >
                Get access to sneaker bots, early drop links and every raffle,
                all in one place. So you can start getting sneakers for retail
                prices.
              </div>
              <div
                className={
                  styles['landing__main__secured-banner__content__link']
                }
              >
                <a
                  href="https://secured.community"
                  className={
                    styles['landing__main__secured-banner__content__link__link']
                  }
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
